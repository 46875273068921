body {
  margin: 0;
  font-family: "Open sans", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87)
}

body, html, #root {
  height: 100%;
}

.MuiInputBase-root {
  background-color: white !important;
}
